import React from "react";
import YouTube from "react-youtube-embed";
import { publications } from "../data-publications";

import { Helmet } from "react-helmet";

export const Research = (props) => {
  return (
    <>
      <Helmet>
        <title>Research | Frederic Robinson</title>
        <meta name="title" content="Research | Frederic Robinson" />
      </Helmet>
      <div
        style={{ whiteSpace: "pre-wrap" }}
        className="flex flex-col items-center"
      >
        <div className="px-5 lg:flex-grow max-w-screen-lg flex flex-col md:text-left md:items-center items-center">
          {/* <h1 className="justify-between w-full title-font text-2xl md:text-3xl mb-8 font-medium">
            Using Sound to Make Our Machines Better Communicators
          </h1>
          <div className="flex flex-col justify-between lg:flex-row items-center lg:items-start w-full mb-8">
            <p className="lg:w-full leading-relaxed text-gray-900 text-">
              As a Scientia PhD Candidate at Sydney's National Facility for
              Human-Robot Interaction Research, I conducted interaction design
              research in the field of Social Robotics. Specifically, I
              investigated how spatial interactive audio can make robots appear
              more trustworthy, capable, and safe. The findings of my research
              support interaction designers, engineers, and other stake-holders
              faced with the complex task of integrating robots into human
              environments, and have applications across domestic robots, and
              aged and healthcare. My methods included prototyping, developing
              user flows and mental models, conducting expert interviews, case
              study analyses, and qualitative and quantitative user studies.
            </p>
          </div>
          <div className="border-t mt-8 w-full border-gray-700 " /> */}
          {/* <YouTube id={"nK2t9y6G8VA"} /> */}
          {/* <div className="border-t my-6 w-full border-gray-700 " /> */}
          <div className="flex flex-row justify-between w-full mt-20 mb-6">
            <h1 className="w-1/2 text-2xl md:text-3xl mb-3 md:mb-0 font-medium">
              Publications
            </h1>
            <span>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://scholar.google.ch/citations?user=zf1d6KAAAAAJ&hl=en&oi=ao"
              >
                <img src="/images/google-scholar.png" width="46px" alt=""></img>
              </a>
            </span>
          </div>
          <div>
            {Object.keys(publications).map((publication) => (
              <Publication key={publication} publication={publication} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

const Publication = (props) => {
  const publication = publications[props.publication];

  return (
    <div className="my-4 text-gray-900 text-sm">
      <span
        className={`${
          publication.firstAuthor.bold ? "font-bold text-black" : ""
        }`}
      >
        {publication.firstAuthor.name + ", "}
      </span>
      <span
        className={`${
          publication.secondAuthor.bold ? "font-bold text-black" : ""
        }`}
      >
        {publication.secondAuthor.name && publication.secondAuthor.name + ", "}
      </span>
      <span>{publication.otherAuthors + ". "}</span>
      <span>{'"' + publication.title + '." '}</span>

      <span className="italic">{publication.journal + ". "}</span>
      <span>{publication.publisher && publication.publisher + ". "}</span>
      <span>{publication.year && publication.year + ". "}</span>
      <div></div>
      <span className="italic ml-8">
        {publication.comments && publication.comments}
        {publication.links && publication.comments && ", "}
      </span>

      {publication.links &&
        publication.links.map((link) => (
          <span key={link.url}>
            <a
              className="underline text-gray-900 hover:text-blue-700 transition-all duration-200"
              target="_blank"
              rel="noreferrer"
              href={link.url}
            >
              {link.name}
            </a>
            <span>{"  "}</span>
          </span>
        ))}
    </div>
  );
};
