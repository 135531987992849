export const publications = {
  robinson_robot_2024: {
    firstAuthor: { name: "Robinson, Frederic Anthony", bold: true },
    secondAuthor: { name: "", bold: true },
    otherAuthors: "Mari Velonaki, and Oliver Bown",
    title: "Robot Sound in Distributed Audio Environments",
    journal: "Sound and Robotics",
    publisher: "Routledge",
    year: "2024",
    comments: "",
    links: [
      {
        name: "Publication",
        url: "https://www.routledge.com/Sound-and-Robotics-Speech-Non-verbal-audio-and-Robotic-Musicianship/Savery/p/book/9781032340838",
      },
    ],
  },
  bown_managing_2024: {
    firstAuthor: { name: "Bown, Oliver", bold: false },
    secondAuthor: { name: "Frederic Anthony Robinson", bold: true },
    otherAuthors: "Kurt Mikolajczyk, and Sam Ferguson",
    title: "Managing complexity in multi-device environments",
    journal: "The Routledge Handbook of Sound Design",
    publisher: "Focal Press",
    year: "2024",
    comments: "",
    links: [
      {
        name: "Publication",
        url: "https://www.taylorfrancis.com/chapters/edit/10.4324/9781003325567-7/managing-complexity-multi-device-environments-oliver-bown-frederic-robinson-kurt-mikolajczyk-sam-ferguson",
      },
    ],
  },
  robinson_spatially_2023: {
    firstAuthor: { name: "Robinson, Frederic Anthony", bold: true },
    secondAuthor: { name: "", bold: true },
    otherAuthors: "Oliver Bown, and Mari Velonaki",
    title: "Spatially Distributed Robot Sound: A Case Study",
    journal:
      "Proceedings of the DIS ’23: Designing Interactive Systems Conference",
    publisher: "ACM",
    year: "2023",
    comments: "Honorable Mention Award",
    links: [
      {
        name: "Publication",
        url: "https://dl.acm.org/doi/10.1145/3563657.3596095",
      },
    ],
  },
  robinson_designing_2023: {
    firstAuthor: { name: "Robinson, Frederic Anthony", bold: true },
    secondAuthor: { name: "", bold: true },
    otherAuthors: "",
    title:
      "Designing Sound for Social Robots: Advancing Professional Practice through Design Principles",
    journal: "PhD Thesis",
    publisher: "UNSW",
    year: "2023",
    comments: "",
    links: [
      {
        name: "Thesis",
        url: "https://unsworks.unsw.edu.au/entities/publication/16f6f263-b120-4e79-9d49-5a2ada19c1cd",
      },
    ],
  },
  robinson_special_2023: {
    firstAuthor: { name: "Robinson, Frederic Anthony", bold: true },
    secondAuthor: { name: "", bold: true },
    otherAuthors:
      "Katsumi Watanabe, Hannah Pelikan, Luisa Damiano, Oliver Bown, and Mari Velonaki (Guest Editors)",
    title: "Special Issue on Sound in Human-Robot Interaction",
    journal: "ACM Transactions on Human-Robot Interaction",
    publisher: "ACM",
    year: "2023",
    comments: "",
    links: [
      {
        name: "Special Issue",
        url: "https://dl.acm.org/toc/thri/2023/12/4",
      },
    ],
  },
  robinson_robot_2022: {
    firstAuthor: { name: "Robinson, Frederic Anthony", bold: true },
    secondAuthor: { name: "", bold: true },
    otherAuthors: "Oliver Bown, and Mari Velonaki",
    title: "The Robot Soundscape",
    journal:
      "Cultural Robotics: Social Robots and Their Emergent Cultural Ecologies",
    publisher: "Springer Nature Group",
    year: "2023",
    comments: "",
    links: [
      {
        name: "Publication",
        url: "https://doi.org/10.1007/978-3-031-28138-9_3",
      },
    ],
  },
  robinson_designing_2022: {
    firstAuthor: { name: "Robinson, Frederic Anthony", bold: true },
    secondAuthor: { name: "", bold: true },
    otherAuthors: "Oliver Bown, and Mari Velonaki",
    title: "Designing Sound for Social Robots: Candidate Design Principles",
    journal: "International Journal of Social Robotics",
    publisher: "Springer Nature",
    year: "2022",
    comments: "",
    links: [
      {
        name: "Publication",
        url: "https://link.springer.com/article/10.1007/s12369-022-00891-0",
      },
    ],
  },
  robinson_crafting_2022: {
    firstAuthor: { name: "Robinson, Frederic Anthony", bold: true },
    secondAuthor: { name: "", bold: true },
    otherAuthors: "Mari Velonaki, and Oliver Bown",
    title:
      "Crafting the Language of Robotic Agents: A Vision for Electroacoustic Music in Human-Robot Interaction",
    journal: "Organised Sound",
    publisher: "Cambridge University Press",
    year: "2022",
    comments: "",
    links: [
      {
        name: "Publication",
        url: "https://www.cambridge.org/core/journals/organised-sound/article/abs/crafting-the-language-of-robotic-agents-a-vision-for-electroacoustic-music-in-humanrobot-interaction/3551E61DC3B8CE0550EFE21D31917521",
      },
    ],
  },
  robinson_smooth_2021: {
    firstAuthor: { name: "Robinson, Frederic Anthony", bold: true },
    secondAuthor: { name: "", bold: true },
    otherAuthors: "Mari Velonaki, and Oliver Bown",
    title:
      "Smooth Operator: Tuning Robot Perception Through Artificial Movement Sound",
    journal:
      "Proceedings of the 2021 ACM/IEEE International Conference on Human-Robot Interaction (HRI '21)",
    publisher: "ACM",
    year: "2021",
    comments: "Best Paper Candidate",
    links: [
      {
        name: "Publication",
        url: "https://dl.acm.org/doi/10.1145/3434073.3444658",
      },
      { name: "Presentation Video", url: "https://youtu.be/mF6Fmy-eFrk" },
    ],
  },
  robinson_debris_2021: {
    firstAuthor: { name: "Robinson, Frederic Anthony", bold: true },
    secondAuthor: { name: "", bold: true },
    otherAuthors: "",
    title:
      "Debris: A playful interface for direct manipulation of audio waveforms",
    journal:
      "Proceedings of the International Conference on New Interfaces for Musical Expression",
    publisher: "",
    year: "2021",
    comments: "",
    links: [
      { name: "Publication", url: "https://nime.pubpub.org/pub/xn761337" },
      { name: "Demonstration Video", url: "https://www.robinson.audio/debris" },
      {
        name: "Presentation Video",
        url: "https://www.youtube.com/watch?v=H04LgbZqc-c",
      },
    ],
  },
  pelikan_sound_2021: {
    firstAuthor: { name: "Pelikan, Hannah", bold: false },
    secondAuthor: { name: "Frederic Anthony Robinson", bold: true },
    otherAuthors:
      "Leelo Keevallik, Mari Velonaki, Mathias Broth, and Oliver Bown",
    title: "Sound in Human-Robot Interaction",
    journal:
      "Companion of the 2021 ACM/IEEE International Conference on Human-Robot Interaction (HRI '21 Companion)",
    publisher: "ACM",
    year: "2021",
    comments: "HRI21 Workshop",
    links: [
      {
        name: "Publication",
        url: "https://dl.acm.org/doi/10.1145/3434074.3444871",
      },
      {
        name: "Workshop Website",
        url: "https://r00binson.wixsite.com/soundinhri",
      },
    ],
  },
  robinson_implicit_2020: {
    firstAuthor: { name: "Robinson, Frederic Anthony", bold: true },
    secondAuthor: { name: "", bold: true },
    otherAuthors: "Oliver Bown, and Mari Velonaki",
    title:
      "Implicit Communication through Distributed Sound Design: Exploring a New Modality in Human-Robot Interaction",
    journal:
      "Companion of the 2020 ACM/IEEE International Conference on Human-Robot Interaction (HRI '20 Companion)",
    publisher: "ACM",
    year: "2020",
    comments: "HRI Pioneers Workshop",
    links: [
      {
        name: "Publication",
        url: "https://dl.acm.org/doi/10.1145/3371382.3377431",
      },
      {
        name: "Presentation Video",
        url: "https://www.youtube.com/watch?v=W8NMAAxh1mc",
      },
    ],
  },
  albastaki_augmenting_2020: {
    firstAuthor: { name: "Albastaki, Almohannad", bold: false },
    secondAuthor: { name: "Frederic Anthony Robinson", bold: true },
    otherAuthors: "Marius Hoggenmueller, and Luke Hespanhol",
    title: "Augmenting Remote Interviews through Virtual Experience Prototypes",
    journal:
      "32nd Australian Conference on Human-Computer Interaction (OzCHI '20)",
    publisher: "ACM",
    year: "2020",
    comments: "Steve Howard Award for the Best Student Paper",
    links: [
      {
        name: "Publication",
        url: "https://dl.acm.org/doi/10.1145/3441000.3441057",
      },
    ],
  },
  robinson_audio_2020: {
    firstAuthor: { name: "Robinson, Frederic Anthony", bold: true },
    secondAuthor: { name: "", bold: true },
    otherAuthors: "",
    title:
      "Audio Cells: A Spatial Audio Prototyping Environment for Human-Robot Interaction",
    journal:
      "Fourteenth International Conference on Tangible, Embedded, and Embodied Interaction (TEI '20)",
    publisher: "ACM",
    year: "2020",
    comments:
      "Student Design Challenge Best Implementation Award, Student Design Challenge People's Choice Award",
    links: [
      { name: "Publication", url: "https://doi.org/10.1145/3374920.3374999" },
    ],
  },
  robinson_gestural_2015: {
    firstAuthor: { name: "Robinson, Frederic Anthony", bold: true },
    secondAuthor: { name: "", bold: true },
    otherAuthors: "Cedric Spindler, Volker Böhm, and Erik Oña",
    title:
      "Gestural Control in Electronic Music Performance: Sound Design Based on the 'striking' and 'bowing' Movement Metaphors",
    journal:
      "Proceedings of the Audio Mostly 2015 on Interaction With Sound - AM '15",
    publisher: "ACM",
    year: "2015",
    comments: "",
    links: [
      { name: "Publication", url: "https://doi.org/10.1145/2814895.2814901" },
      {
        name: "Project",
        url: "https://www.robinson.audio/projects/gesture-based-performance-system",
      },
    ],
  },
};
